const accounting = (val) => {
  const fValue = parseFloat(val)

  if (isNaN(fValue)) {
    return
  }
  if (fValue < 0) {
    const formatted = Math.abs(fValue)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return `(${formatted})`
  } else {
    return `${fValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} `
  }
}
const hoursMinutes = (val) => {
  if (!val) return
  const hours = val >= 0 ? Math.floor(val / 60) : Math.ceil(val / 60)
  const minutes = val % 60
  return `${val < 0 ? '-' : ''}${Math.abs(hours)
    .toString()
    .padStart(2, '0')}:${Math.abs(minutes).toString().padStart(2, '0')}`
}
const percentFormat = (val) => {
  if (!val) return
  return `${val}%`
}

export { accounting, hoursMinutes, percentFormat }
